import Scene from './3D';
import React from 'react';

import Welcome from './steps/Welcome';
import YourName from './steps/YourName';
import YourLikes from './steps/YourLikes';
import YourDislikes from './steps/YourDislikes';
import Result from './steps/Result';
import Marketing from './Marketing';
import DocumentMeta from 'react-document-meta';
import Warning from './steps/Warning';

const descriptions = [
	'Get your Metaverse future told!',
	'Find out what you\'ll look like in the Metaverse!',
	'Generate your future in the Metaverse!',
	'Metafy your future in the Metaverse!',
]

class App extends React.Component {
	constructor() {
		super();
		this.state = {
			step: 0,
			data: {
				name: undefined,
				likes: undefined,
				dislikes: undefined,
			},
			avatarConfig: {}
		};

		this.description = descriptions[Math.floor(Math.random() * descriptions.length)];
		this.scene = <Scene />

		this.steps = [
			Welcome,
			Warning,
			YourName,
			YourLikes,
			YourDislikes,
		];

		this.avatarListener = (e) => {
			this.setState({
				avatarConfig: e.detail.config,
			})
		};
	}

	nextStep(title, value) {
		if (this.state.step < this.steps.length) {
			const updatedData = {
				...this.state.data
			};
			if (title) updatedData[title] = value;

			this.setState({
				data: updatedData,
				step: this.state.step + 1,
			})

			// emit global event
			window.dispatchEvent(new CustomEvent('step-changed', {
				detail: {
					step: this.state.step,
					steps: this.steps.length,
					data: this.state.data,
				}
			}));
		} else {
			this.setState({
				data: {},
				step: 1,
			})
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	componentDidMount() {
		window.addEventListener('avatar-ready', this.avatarListener);
		if (window.location.pathname.match(/.5$/) > 0) {
			window.location.href = window.location.pathname.split('.')[0] + '-1';
		}

		const split = window.location.pathname.split('/');
		if (window.location.pathname.match(/^\/future/) && !isNaN(Number(split[2].split('-')[0]))) {
			fetch('https://api.youaresometa.com/cache/' + String(split[2]).split('-')[0] + '.json')
				.then(res => res.json())
				.then(data => {
					const possibleIndex = parseInt(split[2].split('-')[1]);
					const newData = {
						...data,
						...data.inputs,
						offset: !isNaN(possibleIndex) ? possibleIndex : 0,
						newUser: true,
					};
					if (newData.avatar) {
						this.avatarListener({
							detail: {
								config: newData.avatar,
							}
						});
						setTimeout(() => {
							window.dispatchEvent(new CustomEvent('load-avatar', {
								detail: {
									config: newData.avatar,
								}
							}));
						}, 1)
					} else {
						window.dispatchEvent(new CustomEvent('load-avatar', {
							detail: {
								config: {},
							}
						}));
					}
					this.setState({
						data: {
							...newData
						},
						step: this.steps.length,
					});

					// emit global event
					window.dispatchEvent(new CustomEvent('step-changed', {
						detail: {
							step: this.steps.length,
							steps: this.steps.length,
							data: {
								...newData
							},
						}
					}));
				});
		}
	}
	componentWillUnmount() {
		window.removeEventListener('avatar-ready', this.avatarListener);
	}

	render() {
		const Step = this.steps[this.state.step];

		return (<DocumentMeta title='Metaverse Generator' description={this.description} meta={{
			name: {
				keywords: 'metaverse, meta, vr',
				'twitter:site': '@6connect',
				'twitter:title': 'You are so Meta!',
				'twitter:card': 'summary',
				'twitter:image': 'https://youaresometa.com/get-meta.jpg',
				'twitter:description': this.description,
			},
			property: {
				'og:image': 'https://youaresometa.com/get-meta.jpg',
				'og:description': this.description,
			}
		}}>
			<div className='bg-foundation text-baseColor w-full'>
				<div className="fixed w-full h-full inset-0">
					{this.scene}
				</div>
				<div className="lg:flex min-h-screen flex-wrap">
					<div className="relative min-h-1/2 min-h-screen w-full lg:w-1/2 flex items-end lg:items-center lg:justify-center text-lg">
						<div className="relative z-10 mt-64 lg:mt-0 text-center w-full">
							<div className="m-2 text-white">
								{(this.state.step === 0 || this.state.step === this.steps.length) ? <div className='relative z-10'>
									<div className='text-4xl'>Our Metaverse</div>
									<div className='text-2xl'>your meta-future</div>
									<div className='text-xs'>
										Brought to you by
										<a href='https://www.6connect.com/announcements/announcing-6meta/'><img src='/6connect.svg' alt='6connect' className='w-20 max-w-full my-2 ml-1 inline-block' /></a>
									</div>
								</div> : <></>}
								{this.state.step < this.steps.length ?
									<Step data={this.state.data} nextStep={this.nextStep.bind(this)} /> :
									<Result data={this.state.data} avatar={this.state.avatarConfig} nextStep={this.nextStep.bind(this)} />
								}
							</div>
						</div>
					</div>
				</div>
				<div className='relative z-10'>
					<Marketing />
				</div>
			</div>
		</DocumentMeta>);
	}
}

export default App;
