
// contains [wordToFilter, replacement]
// try to pick a replacement word that will contextually match the wordToFilter.
const badWords = [
	['nigger', 'gentleman'],
	['niggers', 'gentleman'],
	['nigers', 'gentleman'],
	['niger', 'gentleman'],
	['n1gger', 'gentleman'],
	['n1gg3r', 'gentleman'],
	['nigga', 'bro'],
	['niggas', 'bros'],
	['rape', 'slap'],
	['raped', 'slapped'],
	['raping', 'slapping'],
	['rapist', 'serial slapper'],
	['child pornography', 'pirated movies'],
	['child porn', 'pirated movies'],
	['child porno', 'pirated movies'],
	['faggot', 'stinky'],
	['faggots', 'stinkies'],
	['fag', 'stinky'],
	['fagg', 'stinky'],
	['fag1t', 'stinky'],
	['faget', 'stinky'],
	['fagg1t', 'stinky'],
	['faggit', 'stinky'],
	['fagg0t', 'stinky'],
	['fagit', 'stinky'],
	['fags', 'stinkies'],
	['fagz', 'stinky'],
]

export function censorString(string) {
	let output = string;
	for (let i = 0; i < badWords.length; i++) {
		const string = badWords[i][0];
		const replacement = badWords[i][1];
		const exp = new RegExp(`\\b${string}\\b`, 'gi');
		output = output.replace(exp, replacement);
	}
	return output;
}