import React from "react";

class Blob extends React.Component {
	constructor() {
		super();
		this.bubbles = [];
		this.canvasRef = React.createRef();
		this.context = null;

		this.bubbleSize = 75;
		this.bubbleVariance = 3;
		this.bubbleCount = 10; // number of bubbles along the shortest side
	}

	componentDidMount() {
		this.context = this.canvasRef.current.getContext('2d');
		this.resize();
		window.requestAnimationFrame(this.update.bind(this));
		this.resizeListener = this.checkResize.bind(this);
		window.addEventListener('resize', this.resizeListener);

		this.resizeCheckInterval = setInterval(this.resizeListener, 500);
	}

	componentWillUnmount() {
		this.unmounting = true;
		window.removeEventListener('resize', this.resizeListener);
		window.clearInterval(this.resizeCheckInterval);
	}

	checkResize() {
		if (!this.canvasRef.current) return;
		if (
			this.canvasRef.current.clientHeight * window.devicePixelRatio !== this.canvasRef.current.height ||
			this.canvasRef.current.clientWidth * window.devicePixelRatio !== this.canvasRef.current.width
		) {
			this.resize();
		}
	}
	resize() {
		this.bubbleSize = 75 * window.devicePixelRatio;
		this.canvasRef.current.width = this.canvasRef.current.clientWidth * window.devicePixelRatio;
		this.canvasRef.current.height = this.canvasRef.current.clientHeight * window.devicePixelRatio;
		this.fillBubbles();
	}

	createBubble(x, y, size) {
		return {
			x: Math.max(this.bubbleSize * 1.5, Math.min(x, this.canvasRef.current.width - this.bubbleSize * 1.2)),
			y: Math.max(this.bubbleSize * 1.5, Math.min(y, this.canvasRef.current.height - this.bubbleSize * 1.2)),
			size,
			r: Math.random() * Math.PI * 2,
			speed: (Math.random() * 2 - 1) + 0.75,
		}
	}

	fillBubbles() {
		const { bubbleCount, bubbleSize, bubbleVariance } = this;
		let { width, height } = this.canvasRef.current;
		this.bubbles = [];

		const positionVariance = bubbleVariance * bubbleSize;

		// top bubbles
		for (let i = 0; i < bubbleCount; i++) {
			const x = (i / bubbleCount) * width;
			const y = Math.random() * positionVariance + bubbleSize;
			this.bubbles.push(this.createBubble(x, y, bubbleSize));
		}

		// bottom bubbles
		for (let i = 0; i < bubbleCount; i++) {
			const x = (i / bubbleCount) * width;
			const y = height - (Math.random() * positionVariance) - bubbleSize;
			this.bubbles.push(this.createBubble(x, y, bubbleSize));
		}

		// left bubbles
		for (let i = 0; i < bubbleCount; i++) {
			const x = Math.random() * positionVariance + bubbleSize;
			const y = (i / bubbleCount) * height;
			this.bubbles.push(this.createBubble(x, y, bubbleSize));
		}

		// right bubbles
		for (let i = 0; i < bubbleCount; i++) {
			const x = width - (Math.random() * positionVariance) - bubbleSize;
			const y = (i / bubbleCount) * height;
			this.bubbles.push(this.createBubble(x, y, bubbleSize));
		}
	}

	update() {
		if (this.unmounting) return;
		window.requestAnimationFrame(this.update.bind(this));

		if (window.innerWidth < 1024 || !this.context || !this.context.canvas) {
			return;
		}
		const ctx = this.context;

		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		ctx.fillStyle = color;

		for (let index = 0; index < this.bubbles.length; index++) {
			const bubble = this.bubbles[index];
			const { x, y, size } = bubble;
			let x2 = Math.cos(bubble.r + (Date.now() / 1000) * bubble.speed) * size * 0.1;
			let y2 = Math.sin(bubble.r + (Date.now() / 1000) * bubble.speed) * size * 0.1;
			ctx.beginPath();
			ctx.arc(x + x2, y + y2, size, 0, 2 * Math.PI);
			ctx.fill();
		}

	}

	render() {
		return (
			<canvas style={{
				position: 'absolute',
				top: '-2rem',
				left: '-2rem',
				width: 'calc(100% + 4rem)',
				height: 'calc(100% + 4rem)',
			}} className='hidden lg:block' ref={this.canvasRef} />
		);
	}
}

let color = '#4d9fff';


window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
	event.matches ? goDark() : goLight();
});
function goDark() {
	color = '#4d9fff';
}
function goLight() {
	color = '#4d9fff';
}

if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
	goDark();
} else {
	goLight();
}


export default Blob;