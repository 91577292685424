import Button from "../components/button";
import Container from "../components/container";

function Welcome({ nextStep }) {
	return (
		<Container className="text-center">
			<p>Having trouble getting a grasp on all this "Metaverse" talk? Using our patent pending AI technology, we can help show you what your meta-future will be!</p>
			<Button onClick={() => {
				nextStep();
			}}>get meta</Button>
		</Container>
	);
}

export default Welcome;
