import React from "react";
import { css } from '@emotion/css'
import Input from "./input-text";
import Button from "./button";
import { censorString } from "../shared/contentFilter";

const wordStyle = css`
	transition: transform 10s linear, opacity 1s, background-color 0.25s;
	border-radius: 0.25rem;
	position: absolute;
	padding: 0 0.5rem;
	cursor: pointer;
	user-select: none;
	background-color: var(--color-primary);
	&:hover {
		opacity: 1;
		background-color: var(--color-secondary);
	}
`;

function makeBlocky(number, factor = 10) {
	return Math.floor(number / factor) * factor;
}

class WordCloud extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedWords: []
		};

		this.inputRef = React.createRef();

		this.words = [];
	}

	setDivRef(div) {
		this.div = div;
	}

	getWords() {
		let string = "";
		for (let index = 0; index < this.state.selectedWords.length; index++) {
			const word = this.state.selectedWords[index];
			if (index > 0) string += ", ";
			string += word.trim();
		}
		return string;
	}

	randNegPos() {
		return Math.random() < 0.5 ? -1 : 1;
	}

	randomPosition(word) {
		try {
			if (!word.ypos) word.ypos = makeBlocky(Math.random() * (this.div.offsetHeight - 40), 40);
			if (!word.direction) word.direction = this.randNegPos();
			word.direction *= -1;
			const x = word.direction > 0 ? Math.min(-word.clientWidth, -50) : this.div.clientWidth;
			const y = word.ypos;
			return `translate3d(${x}px, ${y}px, 0)`;
		} catch (e) {
			return `translate3d(0, 0, 0)`;
		}
	}

	addWord(word) {
		word = censorString(word);
		if (word.length > 1 && this.state.selectedWords.indexOf(word) === -1 && this.state.selectedWords.length < this.props.limit) {
			const newWords = [...this.state.selectedWords, word];
			this.setState({
				selectedWords: newWords
			});

			if (this.props.onChange) {
				this.props.onChange(newWords);
			}
		}
	}

	componentDidMount() {
		if (this.props.value) {
			this.setState({
				selectedWords: this.props.value.split(",")
			});
		}

		this.possibleWords = [];
		for (let index = 0; index < this.props.words.length; index++) {
			const text = this.props.words[index];
			const word = document.createElement("div");
			word.textContent = text;
			word.className = wordStyle;
			word.reset = true
			word.style.opacity = 0;
			word.style.transform = this.randomPosition(word);
			word.addEventListener("click", (e) => {
				e.preventDefault();
				this.addWord(text);
			})
			this.possibleWords.push(word);
		}

		//shuffle the words
		for (let i = this.possibleWords.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[this.possibleWords[i], this.possibleWords[j]] = [this.possibleWords[j], this.possibleWords[i]];
		}

		this.timeouts = {};
		this.interval = setInterval(() => {
			if (this.div && this.possibleWords.length > 0) {
				const word = this.possibleWords.splice(0, 1)[0];
				this.words.push(word);
				this.div.appendChild(word);
				word.style.transform = this.randomPosition(word);
				word.style.opacity = 0;
				window.requestAnimationFrame(() => {
					word.style.opacity = 1;
				});

				const timeout = setTimeout(() => {
					this.possibleWords.push(word);
					this.div.removeChild(word);
					word.reset = true;
					delete this.timeouts[timeout]
				}, 10000);
				this.timeouts[timeout] = true;


				const timeout2 = setTimeout(() => {
					word.style.opacity = 0;
					delete this.timeouts[timeout2]
				}, 10000 - 1000);
				this.timeouts[timeout2] = true;
			}
		}, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		for (const timeout in this.timeouts) {
			clearTimeout(timeout);
		}
	}


	render() {
		const atLimit = this.state.selectedWords.length >= this.props.limit;

		return (<div>
			<p className={"text-center " + (atLimit ? ' text-secondary text-black' : '')}>pick up to {this.props.limit} {atLimit ? '✅' : ''}</p>
			<div style={{
				padding: "25% 50%",
				width: "100%",
				boxSizing: "border-box",
				position: "relative",
			}}>
				<div className={"absolute inset-0 overflow-hidden rounded bg-primary-800" + (atLimit ? ' opacity-50 cursor-not-allowed' : '')} ref={this.setDivRef.bind(this)}></div>
			</div>
			<div className={"flex justify-center items-center" + (atLimit ? ' opacity-50 cursor-not-allowed' : '')} onKeyPress={e => {
				if (e.key === "Enter") {
					this.addWord(this.inputRef.current.value);
					this.inputRef.current.value = "";
				}
			}}>
				<Input disabled={atLimit} ref={this.inputRef} maxLength={Math.floor(256 / this.props.limit)} placeholder="Type your own..." className="w-9/12" />
				<Button className="ml-2" onClick={() => {
					this.addWord(this.inputRef.current.value);
					this.inputRef.current.value = "";
				}}>Add</Button>
			</div>
			<div>
				{this.state.selectedWords.map((word, index) => {
					return (<span className="rounded px-2 m-1 cursor-pointer inline-block select-none" key={index} onClick={() => {
						const newWords = this.state.selectedWords.filter((w) => w !== word);
						this.setState({
							selectedWords: newWords,
						});
						if (this.props.onChange) {
							this.props.onChange(newWords);
						}
					}}>{word} ❌</span>)
				})}
			</div>
		</div>);
	}
}

export default WordCloud;
