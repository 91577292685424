import Blob from "./blob";


function Container(props) {
	return (<div className={"w-96 max-w-full mx-auto my-4 rounded-3xl px-4 py-1 bg-primary relative"}>
		<Blob />
		<div className="relative z-10">
			{props.children}
		</div>
	</div>);
}

export default Container;
