import React, { useRef, useState } from "react";
import Button from "../components/button";
import Container from "../components/container";
import WordCloud from "../components/word-cloud";

function YourLikes({ nextStep, data }) {
	const [highlight, setHighlight] = useState(false);
	const wordRef = useRef(null);

	return (
		<Container className="text-center">
			<p>
				next up, what are some things you like? keep it short!
			</p>
			<WordCloud onChange={(array) => {
				setHighlight(array.length > 0);
			}} limit={4} ref={wordRef} value={data.likes} words={[
				//List of commonly liked things
				"Video games",
				"Virtual reality",
				"Pizza",
				"Coffee",
				"Birds",
				"Cats",
				"Dogs",
				"Cows",
				"Horses",
				"Bears",
				"Penguins",
				"Dolphins",
				"Sharks",
				"Whales",
				"Sunshine",
				"Rainbows",
				"Cars",
				"Bikes",
				"Movies",
				"Music",
				"Books",
				"Twitter",
				"Computers",
				"TV shows",
				"Food",
				"Art",
				"Nature",
				"Fashion",
				"Politics",
				"Religion",
				"Philosophy",
				"Science",
				"Technology",
				"Lord of the Rings",
				"Harry Potter",
				"Star Wars",
				"Star Trek",
			]} />
			<div className="flex justify-center items-center">
				<Button className={highlight ? 'bg-secondary text-black' : ''} onClick={() => {
					const words = wordRef.current.getWords();
					if (words.length > 0) nextStep('likes', words);
				}}>next step</Button>
			</div>
		</Container>
	);
}

export default YourLikes;
