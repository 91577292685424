function SellingPoint(props) {
	return (<div className={"rounded-3xl bg-primary px-4 py-1 my-8 text-center max-w-lg w-3/4 " + props.className}>
		<h3 className="text-lg text-white">
			{props.children}
		</h3>
	</div>)
}

function TextSection(props) {
	return (<div className="rounded bg-foundation px-4 py-1 my-8 max-w-2xl w-3/4 self-center">
		{props.children}
	</div>)
}

function Marketing() {
	return (<div className="mx-auto max-w-full pb-24 px-2" style={{ width: '1200px', marginTop: '50vh', }}>
		<div className="rounded bg-primary text-white px-4 py-1 my-8 mx-1 text-center">
			<h2 className="text-lg">Sign into the <b>Mother of Metaverses</b> today!</h2>
			<h1 className="text-4xl">MoM</h1>
			<p className="text-center">
				<a href="https://www.6connect.com/announcements/announcing-6meta/">Learn More</a>
			</p>
		</div>

		<div className="flex flex-col w-full">
			<TextSection>
				<p className="text-lg">
					<b>MoM</b> is a decentralized AI social network that allows you to connect with your friends and family.
				</p>
			</TextSection>

			<SellingPoint className="self-start">
				Connect with all your friends!
			</SellingPoint>

			<TextSection>
				<p className="text-lg">
					Our target audience is the <b>entire human population</b>, and it doesn't even matter if they don't create their own accounts, <span title="Please don't do this. Nobody do this. This is a joke.">because we can use <b>AI</b> to do it for them.</span> What could go wrong?
				</p>
			</TextSection>

			<SellingPoint className="self-end">
				Get a diagnosis from your therapist who's wearing the avatar of your favorite movie character!
			</SellingPoint>

			<TextSection>
				<p>
					All you need to get started is a <b>Metaverse ID</b> and a <b>Metaverse Wallet</b>, general knowledge of how cryptocurrency works, a computer powerful enough to compute the hash of a block, and a <b>few other things...</b>
				</p>
			</TextSection>

			<SellingPoint className="self-start">
				Wait 3 hours in the virtual DMV!
			</SellingPoint>
		</div>

		<div className="text-right">
			<div className="rounded bg-foundationAlt inline-block px-4 py-1 mt-48">
				Yeah this isn't going to work
			</div>
		</div>
	</div>);
}

export default Marketing;