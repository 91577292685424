const config = {
	colors: {
		dark: {
			background: 0x0095C7,
			space: 0x2935B5,
			spaceAlt: 0x005FD3,
		},
		light: {
			background: 0x3DCEFF,
			space: 0x2935B5,
			spaceAlt: 0x005FD3,
		}
	}
};

export default config;