import React from "react";
import { css } from "@emotion/css"
import { censorString } from "../shared/contentFilter";

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			characters: 0,
			value: "",
		}
		this.inputRef = React.createRef();
	}

	get value() {
		return censorString(this.state.value);
	}
	set value(value) {
		this.setState({ value: censorString(value) });
	}
	onChange(e) {
		if (this.props.onChange) this.props.onChange(e);
		const value = e.target.value;
		this.setState({ characters: value.length, value: value });
	}
	render() {
		return (<div className={" " + this.props.className}>
			<div className="text-base relative max-w-full">
				<input
					{...this.props}
					value={this.state.value}
					onChange={this.onChange.bind(this)}
					ref={this.inputRef}
					className={"px-4 py-1 bg-transparent border-0 border-b w-full outline-none " + css`
						::placeholder {
							color: white;
							opacity: 0.5;
						}
					`}
				/>
				{this.props.maxLength ? <div className="text-xs outline-none pointer-events-none absolute right-0 top-0 pr-1">
					{this.state.characters}/{this.props.maxLength}
				</div> : <></>}
			</div>
		</div>);
	}
}

export default Input;
