import * as THREE from 'three';
import config from '../config';
import darkModeManager from '../darkModeManager';

const floorGeometry = new THREE.CircleBufferGeometry(25, 32);
const floorMaterial = new THREE.MeshBasicMaterial();
const shadowMaterial = new THREE.ShadowMaterial({ opacity: 0.25 });

function goDark() {
	floorMaterial.color.set(config.colors.dark.background);
	floorMaterial.needsUpdate = true;
}
function goLight() {
	floorMaterial.color.set(config.colors.light.background);
	floorMaterial.needsUpdate = true;
}
darkModeManager(goDark, goLight);

class Floor {
	constructor(parent) {
		this.shadowMesh = new THREE.Mesh(
			floorGeometry,
			shadowMaterial
		)
		this.shadowMesh.receiveShadow = true;
		this.shadowMesh.rotation.x = -Math.PI / 2;
		this.shadowMesh.position.y = 0;
		parent.add(this.shadowMesh);

		this.mesh = this.shadowMesh.clone();
		this.mesh.material = floorMaterial;
		this.mesh.position.y = -0.01;
		parent.add(this.mesh);
	}
}

export default Floor;