import React, { useRef } from "react";
import Button from "../components/button";
import Container from "../components/container";
import Input from "../components/input-text";

function YourName({ nextStep, data }) {

	const textInput = useRef(null);

	const [name, setName] = React.useState(data.name);

	return (
		<Container className="text-center">
			<p>
				what's your name?
			</p>
			<div className="flex justify-center items-center">
				<Input value={name} onChange={e => setName(e.target.value)} maxLength="128" ref={textInput} placeholder="John Smith" className="w-9/12 mr-1" />
				<Button onClick={() => {
					const value = textInput.current.value.trim();
					if (value.length > 0) nextStep('name', value);
				}}>next</Button>
			</div>
		</Container>
	);
}

export default YourName;
