import * as THREE from 'three';
import AvatarMainRig from './rigged_model';
import Poof from '../poof_particles';

class Avatar {
	constructor(config) {
		this.group = new THREE.Group();
		this.blankConfig = {
			skinColor: "#9EC9FF", //pickOne(skinTones), //pick from one of a set of curated skin colors
			shirtColor: "#9EC9FF", //'#' + new THREE.Color(`hsl(${Math.random() * 360}, 60%, 60%)`).getHexString(), // randomly chosen hue with set sat and val
			pantColor: "#9EC9FF",
			torsoHeight: 2,  // value between 2 and 3 that represents the real height of the torso
			headSize: 1.55, //Math.random() + 1, // value between 1 and 2 that represents the real height of the head
			eyeStyle: "dot", //pickOne(eyeStyles), //the eye appearance, picked from a list 
			eyeHeight: 0.5, //Math.random(), // value between 0 and 1 that represents an abstraction of the height of the eyes on the face
			eyeSize: 0.4, //Math.random(), // value between 0 and 1 that represents an abstraction of the eye size
			eyeRotation: 0.5, //Math.random(), //value between 0 and 1 that represents and abstraction of the eye rotation
			browStyle: "none", //pickOne(browStyles), //the eyebrow appearance, picked from a list
			browHeight: 0.5, //Math.random(), //value between 0 and 1 that represents an abstraction of the height of the eyebrows on the face
			browSize: 0.5, //Math.random(), //value between 0 and 1  that represents an abstraction of the scale of the eyebrows
			browRotation: 0.5, //Math.random(), //value between 0 and 1 that represents an abstraction of the rotation of the eyebrows
			mouthStyle: "flat", //pickOne(mouthStyles), //the mouth appearance, picked from a list
			mouthHeight: 0.7, //Math.random(), // value between 0 and 1 that represents an abstraction of the height of the mouth on the face
			mouthSize: 0.5, //Math.random(), // value between 0 and 1 that represents an abstraction of the mouth size
			hairStyle: "none", //pickOne(hairStyles),
			hairColor: "#9EC9FF", //'#' + Math.floor(0xFFFFFF * Math.random()).toString(16).padStart(6, '0'), // randomly chosen color from the entire gamut
		}
		this.defaultMascotConfig = { // this is our "nice" mascot config.
			skinColor: pickOne(skinTones), //pick from one of a set of curated skin colors
			shirtColor: "#25A9DF", //'#' + new THREE.Color(`hsl(${Math.random() * 360}, 60%, 60%)`).getHexString(), // randomly chosen hue with set sat and val
			pantColor: "#000000",
			torsoHeight: 2,  // value between 2 and 3 that represents the real height of the torso
			headSize: 1.55, //Math.random() + 1, // value between 1 and 2 that represents the real height of the head
			eyeStyle: "dot", //pickOne(eyeStyles), //the eye appearance, picked from a list 
			eyeHeight: 0.3, //Math.random(), // value between 0 and 1 that represents an abstraction of the height of the eyes on the face
			eyeSize: 0.5, //Math.random(), // value between 0 and 1 that represents an abstraction of the eye size
			eyeRotation: 0.5, //Math.random(), //value between 0 and 1 that represents and abstraction of the eye rotation
			browStyle: "rounded", //pickOne(browStyles), //the eyebrow appearance, picked from a list
			browHeight: 1, //Math.random(), //value between 0 and 1 that represents an abstraction of the height of the eyebrows on the face
			browSize: 0.3, //Math.random(), //value between 0 and 1  that represents an abstraction of the scale of the eyebrows
			browRotation: 0.4, //Math.random(), //value between 0 and 1 that represents an abstraction of the rotation of the eyebrows
			mouthStyle: "neutral", //pickOne(mouthStyles), //the mouth appearance, picked from a list
			mouthHeight: 0.6, //Math.random(), // value between 0 and 1 that represents an abstraction of the height of the mouth on the face
			mouthSize: 0.5, //Math.random(), // value between 0 and 1 that represents an abstraction of the mouth size
			hairStyle: "high pony", //pickOne(hairStyles),
			hairColor: "#4a2807", //'#' + Math.floor(0xFFFFFF * Math.random()).toString(16).padStart(6, '0'), // randomly chosen color from the entire gamut
		}
		const configDefaults = {
			skinColor: pickOne(skinTones), //pick from one of a set of curated skin colors
			shirtColor: '#' + new THREE.Color(`hsl(${Math.random() * 360}, 60%, 60%)`).getHexString(), // randomly chosen hue with set sat and val
			pantColor: "#000000",
			torsoHeight: 2,  // value between 2 and 3 that represents the real height of the torso
			headSize: 1.55, //Math.random() + 1, // value between 1 and 2 that represents the real height of the head
			eyeStyle: pickOne(eyeStyles), //the eye appearance, picked from a list 
			eyeHeight: Math.random(), // value between 0 and 1 that represents an abstraction of the height of the eyes on the face
			eyeSize: Math.random(), // value between 0 and 1 that represents an abstraction of the eye size
			eyeRotation: Math.random(), //value between 0 and 1 that represents and abstraction of the eye rotation
			browStyle: pickOne(browStyles), //the eyebrow appearance, picked from a list
			browHeight: Math.random(), //value between 0 and 1 that represents an abstraction of the height of the eyebrows on the face
			browSize: Math.random(), //value between 0 and 1  that represents an abstraction of the scale of the eyebrows
			browRotation: Math.random(), //value between 0 and 1 that represents an abstraction of the rotation of the eyebrows
			mouthStyle: pickOne(mouthStyles), //the mouth appearance, picked from a list
			mouthHeight: Math.random(), // value between 0 and 1 that represents an abstraction of the height of the mouth on the face
			mouthSize: Math.random(), // value between 0 and 1 that represents an abstraction of the mouth size
			hairStyle: pickOne(hairStyles),
			hairColor: '#' + Math.floor(0xFFFFFF * Math.random()).toString(16).padStart(6, '0'), // randomly chosen color from the entire gamut
		}
		this.config = {
			...configDefaults,
			...config,
		}

		this.rig = new AvatarMainRig(this.config);
		this.group.add(this.rig.group);

		this.poofParticles = new Poof();
		this.particlesToFace();

		this.group.add(this.poofParticles.particleSystem);
		//console.log(this.poofParticles.particleSystem);

		/*
		// this was the old way, before we got the proper model made
		this.torso = new Torso(this.config);
		this.torso.position = new THREE.Vector3(0, this.config.torsoHeight / 2, 0);
		this.group.add(this.torso.group);

		this.head = new Head(this.config);
		this.torso.group.add(this.head.group);

		setInterval(this.breath.bind(this), 16)
		*/
	}

	regenerateAll(config) {
		this.config = {
			...this.config,
			...config,
		}

		if (this.rig) {
			this.rig.group.removeFromParent();
			delete this.rig;
		}
		this.rig = new AvatarMainRig(this.config);
		this.group.add(this.rig.group);
	}

	regenerateMainColor(skin, shirt) {
		this.config.skinColor = skin ? skin : pickOne(skinTones);
		this.config.shirtColor = shirt ? shirt : '#' + new THREE.Color(`hsl(${Math.random() * 360}, 60%, 60%)`).getHexString();
		this.rig.updateMat(this.config);
		this.particlesToBody();
		this.poofParticles.start();
	}

	regenerateAllColor(skin, shirt, hair, pants) {
		this.config.hairColor = hair ? hair : '#' + Math.floor(0xFFFFFF * Math.random()).toString(16).padStart(6, '0');
		this.config.pantColor = pants ? pants : "#000000";
		this.rig.updateHair(this.config);
		this.regenerateMainColor(skin, shirt);
	}

	regenerateEyes(style, height, size, rotation) {
		this.config.eyeStyle = style !== undefined ? style : pickOne(eyeStyles);
		this.config.eyeHeight = height !== undefined ? height : Math.random();
		this.config.eyeSize = size !== undefined ? size : Math.random();
		this.config.eyeRotation = rotation !== undefined ? rotation : Math.random();
		this.rig.updateFace(this.config);
		this.particlesToFace();
		this.poofParticles.start();
	}

	regenerateEyebrows(style, height, size, rotation) {
		this.config.browStyle = style !== undefined ? style : pickOne(browStyles);
		this.config.browHeight = height !== undefined ? height : Math.random();
		this.config.browSize = size !== undefined ? size : Math.random();
		this.config.browRotation = rotation !== undefined ? rotation : Math.random();
		this.rig.updateFace(this.config);
		this.particlesToFace();
		this.poofParticles.start();
	}

	regenerateMouth(style, height, size) {
		this.config.mouthStyle = style !== undefined ? style : pickOne(mouthStyles);
		this.config.mouthHeight = height !== undefined ? height : Math.random();
		this.config.mouthSize = size !== undefined ? size : Math.random();
		this.rig.updateFace(this.config);
		this.particlesToFace();
		this.poofParticles.start();
	}

	regenerateHairStyle(style) {
		this.config.hairStyle = style !== undefined ? style : pickOne(hairStyles);
		this.rig.updateHair(this.config);
		this.particlesToFace();
		this.poofParticles.start();
	}

	regenerateHair(style, color) {
		this.config.hairColor = color !== undefined ? color : '#' + Math.floor(0xFFFFFF * Math.random()).toString(16).padStart(6, '0');
		this.regenerateHairStyle(style);
	}

	particlesToFace() {
		this.poofParticles.particleSystem.position.y = 3.75;
		this.poofParticles.particleSystem.position.z = 1.2;
	}

	particlesToBody() {
		this.poofParticles.particleSystem.position.y = 2.5;
		this.poofParticles.particleSystem.position.z = 1.2;
	}

	doLeft() {
		this.rig.turnHead(5);
	}

	doRight() {
		this.rig.turnHead(-5);
	}

	breath() {
		this.torso.group.position.y = Math.sin(Date.now() / 500) * 0.01 + this.torso.position.y;
	}

	update(dt) {
		this.rig.update(dt);
		this.poofParticles.update(dt);
	}

	doStepChange(stepDetails) {

		if (stepDetails.step === 0) {
			this.regenerateAll(this.blankConfig);
		}
		else if (stepDetails.step === 2) {
			this.regenerateEyes();
			this.regenerateEyebrows();
			this.regenerateMouth();
		}
		else if (stepDetails.step === 3) {
			this.regenerateHairStyle();
		}
		else if (stepDetails.step === 4) {
			this.regenerateAllColor();
		}

		//if we're on the penultimate step, we're done generating and can export our data
		if (stepDetails.step === stepDetails.steps - 1) {
			let configResult = this.config;
			window.dispatchEvent(new CustomEvent('avatar-ready', {
				detail: {
					config: configResult,
				}
			}));
		}
	}

	export() {
		return this.config;
	}
}

export default Avatar;

const skinTones = ["#FFC64C"]; //["#8D5524", "#C68642", "#E0AC69", "#F1C27D", "#FFDBAC"];
const hairStyles = ["close", "high pony", "long", "lumpy", "wedge"];
const eyeStyles = ["dot", "symbolic", "symbolic lashed", "circle", "circle lashed"];
const browStyles = ["rounded", "thick", "narrow"];
const mouthStyles = ["flat", "neutral", "smile", "cat", "scowl", "grate"]

function pickOne(arr) {
	return arr[Math.floor(arr.length * Math.random())];
}