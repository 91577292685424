// modified, originally from https://github.com/mrdoob/three.js/blob/master/examples/jsm/objects/Sky.js
import * as THREE from 'three';
import config from '../config';
import darkModeManager from '../darkModeManager';

/**
 * Based on "A Practical Analytic Model for Daylight"
 * aka The Preetham Model, the de facto standard analytic skydome model
 * https://www.researchgate.net/publication/220720443_A_Practical_Analytic_Model_for_Daylight
 *
 * First implemented by Simon Wallner
 * http://simonwallner.at/project/atmospheric-scattering/
 *
 * Improved by Martin Upitis
 * http://blenderartists.org/forum/showthread.php?245954-preethams-sky-impementation-HDR
 *
 * Three.js integration by zz85 http://twitter.com/blurspline
*/

const material = new THREE.MeshBasicMaterial({
	color: 0xFFFFFF,
	side: THREE.BackSide
});

class Sky extends THREE.Mesh {
	constructor() {
		super(new THREE.BoxGeometry(1, 1, 1), material);
	}
}

function goDark() {
	material.color.set(config.colors.dark.background);
}
function goLight() {
	material.color.set(config.colors.light.background);
}
darkModeManager(goDark, goLight);

export { Sky };