import * as THREE from 'three';

const assetPath = "/assets/2d/";
const eyePlaneL = new THREE.PlaneBufferGeometry(0.25, 0.25);
const browPlaneL = new THREE.PlaneBufferGeometry(0.25, 0.125);
const mouthPlane = new THREE.PlaneBufferGeometry(0.4, 0.2); // this will likely change depending on what the images we use need to accommodate them

class Face {
	constructor(config) {
		this.group = new THREE.Group();
		this.eyes = new THREE.Group();
		this.brows = new THREE.Group();
		this.config = config;

		//          min eye height                height range
		this.eyeHeight = 0.02 + (this.config.eyeHeight * 0.13);
		//          min eye size                  size range
		this.eyeSize = 0.5 + (this.config.eyeSize * 0.4);
		//          min rotation                  range
		this.eyeRotation = Math.PI / -8 + (this.config.eyeRotation * (Math.PI / 4));
		//          min brow height               height range
		this.browHeight = this.eyeHeight + (this.eyeSize * 0.1) + (this.config.browHeight * 0.06);
		//          min brow size                 size range
		this.browSize = 0.75 + (this.config.browSize * 0.5);
		//          min rotation                  range
		this.browRotation = Math.PI / -16 + (this.config.browRotation * (Math.PI / 8));
		// min is - so that appears below the "nose"      size range
		this.mouthHeight = -0.24 + (this.config.mouthHeight * 0.06);
		//          min mouth size                    size range
		this.mouthSize = 0.5 + (this.config.mouthSize * 0.3);

		let eyeTexturePath = assetPath + eyeMap[this.config.eyeStyle];
		this.eyeTexture = new THREE.TextureLoader().load(eyeTexturePath);
		this.eyeMat = new THREE.MeshBasicMaterial({
			map: this.eyeTexture,
			transparent: true,
		});

		let browTexturePath = assetPath + browMap[this.config.browStyle];
		this.browTexture = new THREE.TextureLoader().load(browTexturePath);
		this.browMat = new THREE.MeshBasicMaterial({
			map: this.browTexture,
			transparent: true,
		});

		let mouthTexturePath = assetPath + mouthMap[this.config.mouthStyle]
		this.mouthTexture = new THREE.TextureLoader().load(mouthTexturePath);
		this.mouthMat = new THREE.MeshBasicMaterial({
			map: this.mouthTexture,
			transparent: true,
		});

		this.createEyes();
		if (this.config.browStyle !== "none") {
			this.createBrows();
		}
		this.createMouth();
		this.blink();
	}

	////// eye handling //////

	createEyes() {
		// left eye
		if (this.eyeL) {
			this.eyeL.removeFromParent();
			delete this.eyeL;
		}

		this.eyeL = new THREE.Mesh(
			eyePlaneL,
			this.eyeMat,
		)
		this.eyeL.scale.setScalar(this.eyeSize);
		this.eyeL.scale.x = -1 * this.eyeL.scale.x; // flip the mesh by setting it's "size" to negative in the proper direction
		//this.eyeL.position.y = this.eyeHeight;
		this.eyeL.position.x = -0.25 * this.eyeSize; // eyes should be about 1 eye apart by default
		this.eyeL.rotateZ(-1 * this.eyeRotation);
		this.eyes.add(this.eyeL);

		// right eye
		if (this.eyeR) {
			this.eyeR.removeFromParent();
			delete this.eyeR;
		}

		this.eyeR = new THREE.Mesh(
			eyePlaneL,
			this.eyeMat,
		)
		this.eyeR.scale.setScalar(this.eyeSize);
		//this.eyeR.position.y = this.eyeHeight;
		this.eyeR.position.x = 0.25 * this.eyeSize; // eyes should be about 1 eye apart by default
		this.eyeR.rotateZ(this.eyeRotation);
		this.eyes.add(this.eyeR);

		//both eyes together
		this.eyes.position.y = this.eyeHeight;
		this.group.add(this.eyes);
	}

	blink() {
		this.eyeL.scale.y = 0.1;
		this.eyeR.scale.y = 0.1;
		setTimeout(() => {
			this.eyeL.scale.y = this.eyeSize;
			this.eyeR.scale.y = this.eyeSize;
		}, 250);

		setTimeout(this.blink.bind(this), 2000 + (Math.random() * 10000));
	}

	////// eyebrow handling //////

	createBrows() {
		// left eyebrow
		if (this.browL) {
			this.browL.removeFromParent();
			delete this.browL;
		}

		this.browL = new THREE.Mesh(
			browPlaneL,
			this.browMat,
		)
		this.browL.scale.setScalar(this.browSize);
		this.browL.scale.x = -1 * this.browL.scale.x; // flip the mesh by setting it's "size" to negative in the proper direction
		//this.browL.position.y = this.browHeight;
		this.browL.position.x = -0.24 * this.eyeSize;
		this.browL.rotateZ(-1 * this.browRotation);
		this.brows.add(this.browL);

		// right eyebrow
		if (this.browR) {
			this.browR.removeFromParent();
			delete this.browR;
		}

		this.browR = new THREE.Mesh(
			browPlaneL,
			this.browMat,
		)
		this.browR.scale.setScalar(this.browSize);
		//this.browR.position.y = this.browHeight;
		this.browR.position.x = 0.24 * this.eyeSize;
		this.browR.rotateZ(this.browRotation);
		this.brows.add(this.browR);

		//both together
		this.brows.position.y = this.browHeight;
		this.brows.position.z = 0.01;
		this.group.add(this.brows);
	}

	////// mouth handling //////

	createMouth() {
		if (this.mouth) {
			this.mouth.removeFromParent();
			delete this.mouth;
		}

		this.mouth = new THREE.Mesh(
			mouthPlane,
			this.mouthMat,
			//new THREE.MeshLambertMaterial({
			//	color: 0x666666,
			//})
		)
		this.mouth.scale.setScalar(this.mouthSize);
		this.mouth.position.y = this.mouthHeight;
		this.mouth.rotateX(Math.PI / 8)
		this.group.add(this.mouth);
	}

}

export default Face;

const eyeMap = {
	"dot": "dot_eye.png",
	"symbolic": "symbol_eye.png",
	"symbolic lashed": "symbol_lash_eye.png",
	"circle": "circle_eye.png",
	"circle lashed": "circle_lash_eye.png"
}

const browMap = {
	"none": "rounded_eyebrow.png", //even when hidden, define one just in case.
	"rounded": "rounded_eyebrow.png",
	"thick": "angular_eyebrow.png",
	"narrow": "basic_eyebrow.png"
}

const mouthMap = {
	"flat": "mouth_flat.png",
	"neutral": "mouth_base.png",
	"smile": "smiley_mouth.png",
	"cat": "cat_mouth.png",
	"scowl": "frown_mouth.png",
	"grate": "grate_mouth.png"
}