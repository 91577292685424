import * as THREE from 'three';
import Face from './face';
import Hair from './hair';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import MatMaker from './material_maker';

const modelPath = '/assets/3d/DS_person.glb';

class AvatarMainRig {
	constructor(config) {
		this.group = new THREE.Group();
		this.rig = null;
		this.config = config;
		this.bones = {};
		this.animClips = {};
		this.animMixer = null;
		this.materialCanvas = null;

		//load in the rig here, 
		let boundInit = this.initialize.bind(this); //we need to make a bound version of the initializer so it can be used in a callback.

		const loader = new GLTFLoader();
		loader.load(modelPath,
			(gltf) => { //callback on success
				boundInit(gltf);
				console.log("rigged character loaded");
			},
			undefined, //callback on wait
			(error) => { //callback on fail
				console.error(error);
			}
		);

	}

	initialize(loadedRig) {
		//add the model, now that it's loaded.
		if (this.rig) {
			this.rig.removeFromParent();
			delete this.rig;
		}
		this.rig = loadedRig.scene;
		this.group.add(this.rig);

		//define our bones for easier reference.
		this.bones = {};
		//spine and head
		this.bones.root = this.rig.getObjectByName('Root');
		this.bones.spineLower = this.rig.getObjectByName('Spine1');
		this.bones.spineUpper = this.rig.getObjectByName('Spine2');
		this.bones.neck = this.rig.getObjectByName('Neck');
		this.bones.head = this.rig.getObjectByName('Head');
		//arms
		this.bones.shoulderL = this.rig.getObjectByName('Shoulder_L');
		this.bones.armL = this.rig.getObjectByName('UpperArm_L');
		this.bones.forearmL = this.rig.getObjectByName('Forearm_L');
		this.bones.shoulderR = this.rig.getObjectByName('Shoulder_R');
		this.bones.armR = this.rig.getObjectByName('UpperArm_R');
		this.bones.forearmR = this.rig.getObjectByName('Forearm_R');
		//legs
		this.bones.calfL = this.rig.getObjectByName('Calf_L');
		this.bones.shinL = this.rig.getObjectByName('Shin_L');
		this.bones.footL = this.rig.getObjectByName('Foot_L');
		this.bones.calfR = this.rig.getObjectByName('Calf_R');
		this.bones.shinR = this.rig.getObjectByName('Shin_R');
		this.bones.footR = this.rig.getObjectByName('Foot_R');

		//meshes and shadows
		this.bodyMesh = this.rig.getObjectByName('Body');
		this.headMesh = this.rig.getObjectByName('Head_1');
		this.legsMesh = this.rig.getObjectByName('leg');

		this.bodyMesh.castShadow = true;
		this.headMesh.castShadow = true;
		this.legsMesh.castShadow = true;

		//materials
		//all the mesh pieces use the same material, so we only need to get it once
		this.mainMaterial = this.bodyMesh.material;

		this.materialCanvas = new MatMaker(3, 2);
		this.materialCanvas.setSkin(this.config.skinColor);
		this.materialCanvas.setTop(this.config.shirtColor);
		this.materialCanvas.setPants(this.config.pantColor);
		//console.log(this.config.hairColor);
		this.materialCanvas.setHair(this.config.hairColor);
		this.mainMaterial.map = new THREE.CanvasTexture(this.materialCanvas.getCanvas());

		//add our face and hair
		this.createFace();
		this.createHair(this.mainMaterial.map);

		//set our material to the "generated" one? We can probably do this dynamically as it's made and adjusted
		//this.mainMaterial.map = new THREE.TextureLoader().load('/assets/2d/test_pallet.png');
		//this.mainMaterial.map.flipY = false;

		//console.log(this.bodyMesh.material);

		//animation system
		let anims = loadedRig.animations;
		this.animMixer = new THREE.AnimationMixer(this.rig); // the mixer is a simple but fairly standard animation mixer. It handles the playing and blending of animation clips
		//define our animations by name for easy access
		this.animClips = {};
		this.animClips.idle = THREE.AnimationClip.findByName(anims, 'Idle');

		//play the idle animation
		this.animMixer.clipAction(this.animClips.idle).play();

		//console.log(loadedRig);
	}

	createFace() {
		if (this.face) {
			this.face.group.removeFromParent();
			delete this.face;
		}
		// note that we anchor the face to the head bone, not the mesh, so the "origin" is the base of the neck
		this.face = new Face(this.config);
		this.face.group.position.z = this.config.headSize * 0.44;
		this.face.group.position.y = this.config.headSize * 0.14;
		this.face.group.scale.setScalar(this.config.headSize);
		this.bones.head.add(this.face.group);
	}

	updateMat(config) {
		if (config) { this.config = config; }
		this.materialCanvas.setSkin(this.config.skinColor);
		this.materialCanvas.setTop(this.config.shirtColor);
		this.materialCanvas.setPants(this.config.pantColor);
		this.mainMaterial.map = new THREE.CanvasTexture(this.materialCanvas.getCanvas());
	}

	updateFace(config) {
		if (config) { this.config = config; }
		this.createFace();
	}

	createHair(texture) {
		if (this.hair) {
			this.hair.group.removeFromParent();
			delete this.hair;
		}
		this.hair = new Hair(this.config, texture);
		this.bones.head.add(this.hair.group);
		//this.hair.group.position.z = 0.04; //in theory it should be lining up by default, but there seems to be a slight disconnect between where Blender and THREE think this bone anchors at
		this.hair.group.scale.setScalar(1.04);
		//console.log(this.bones.head);
	}

	updateHair(config) {
		if (config) { this.config = config; }
		this.materialCanvas.setHair(this.config.hairColor);
		this.createHair(new THREE.CanvasTexture(this.materialCanvas.getCanvas()));
	}

	turnHead(n) {
		//rotates the head about the neck n degrees clockwise. -n goes the other way
		//used for debugging for now
		this.bones.head.rotateY(n * (Math.PI / 180));
	}

	update(dt) {
		if (this.animMixer) {
			this.animMixer.update(dt);
		}
	}
}

export default AvatarMainRig;