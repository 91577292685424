import Button from "../components/button";
import Container from "../components/container";

function Warning({ nextStep }) {
	return (
		<Container className="text-center">
			<p>This project uses AI to generate a result, content is filtered but still potentially unsafe, especially for younger audiences.</p>
			<p>Continue at your own discretion.</p>
			<Button onClick={() => {
				nextStep();
			}}>continue</Button>
		</Container>
	);
}

export default Warning;
