import * as THREE from 'three';
import React from 'react';

import Environment from './environment';

function lerp(a, b, t) {
	return a + (b - a) * t;
}
class Scene extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('keyup', (event) => {
			//console.log(event);
			if (event.key === "[") {
				console.log("left");
				this.environment.doLeft();
			}

			if (event.key === "]") {
				console.log("right");
				this.environment.doRight();
			}
		});

		window.addEventListener('step-changed', (event) => {
			console.log(event);
			this.environment.doStepChange(event.detail);
		});

		window.addEventListener('load-avatar', (event) => {
			console.log(event);
			this.environment.resetAvatar(event.detail.config);
		});
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', (event) => { });
		window.removeEventListener('step-change', (event) => { });
	}

	initCanvas(e) {
		if (this.canvas) {
			return;
		}
		this.lastFrame = Date.now();
		this.canvas = e;
		this.scene = new THREE.Scene();
		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			canvas: this.canvas,
			alpha: false,
			outputEncoding: THREE.sRGBEncoding,
		});
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		this.camera = new THREE.PerspectiveCamera(40, 1, 4, 1000);

		this.cameraTarget = new THREE.Vector3(0, 2, 0);
		this.cameraTargetOffset = new THREE.Vector3(0, 0, 0);
		this.cameraPosTarget = new THREE.Vector3(0, 4, 8);
		this.cameraPosOffset = new THREE.Vector3(0, 0, 0);

		this.environment = new Environment(this.scene);

		window.addEventListener('resize', this.resize.bind(this));

		if (this.addTestMeshes) {
			//this.addTestMeshes();
		}

		window.requestAnimationFrame(() => {
			this.resize();
			this.draw();
		});
	}

	addTestMeshes() {
		const cube = new THREE.Mesh(
			new THREE.BoxBufferGeometry(1, 1, 1),
			new THREE.MeshNormalMaterial(),
		);
		cube.position.y = 1;
		this.scene.add(cube);

		setInterval(() => {
			cube.rotation.x += 0.01;
			cube.rotation.y += 0.01;
		}, 16);
	}

	resize() {
		const width = this.canvas.clientWidth;
		const height = this.canvas.clientHeight;

		this.renderer.setSize(width, height);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();

		if (window.innerWidth < 1024) {
			this.cameraPosOffset.set(0, 2, 14);
			this.cameraTargetOffset.set(0, -4, 0);
		} else {
			this.cameraPosOffset.set(2, 0, 0);
			this.cameraTargetOffset.set(-2, 0, 0);
		}
	}

	draw() {
		const delta = (Date.now() - this.lastFrame) / 1000;
		this.lastFrame = Date.now();

		const cameraPosTarget = this.cameraPosTarget.clone().add(this.cameraPosOffset);
		const cameraTarget = this.cameraTarget.clone().add(this.cameraTargetOffset);

		cameraPosTarget.y = lerp(cameraPosTarget.y, -20, Math.pow(window.scrollY / window.innerHeight, 2));
		cameraTarget.y = lerp(cameraTarget.y, -10, Math.pow(window.scrollY / window.innerHeight, 2));

		if (this.camera.position.distanceTo(cameraPosTarget) > 0.01) {
			this.camera.position.lerp(cameraPosTarget, 1);
			this.camera.lookAt(cameraTarget);
		}

		this.environment.tick(delta);

		this.renderer.render(this.scene, this.camera);

		requestAnimationFrame(this.draw.bind(this));
	}

	render() {
		return (<canvas className="absolute inset-0 w-full h-full max-w-full max-h-full min-w-full min-h-full" ref={this.initCanvas.bind(this)} />);
	}
}

export default Scene;