import * as THREE from 'three';

import Avatar from './avatar/index';
import Floor from './objects/floor';
import { Sky } from './objects/sky';
import Space from './objects/space';

class Environment {
	constructor(parent) {
		new Floor(parent);

		this.sky = new Sky();
		this.sky.scale.setScalar(100);
		parent.add(this.sky);

		this.space = new Space(parent);

		this.avatar = new Avatar();
		parent.add(this.avatar.group);

		const sunLight = new THREE.DirectionalLight(new THREE.Color(0xffffff), 0.5);
		sunLight.position.set(0, 10, 0);
		this.initShadow(sunLight);
		this.sunLight = sunLight;
		parent.add(sunLight);

		const frontLight = new THREE.DirectionalLight(new THREE.Color(0xffffff), 0.35);
		frontLight.position.set(0, 5, 10);
		parent.add(frontLight);


		this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5); //was 0x64d2d8, which is a little too green and overpowering
		parent.add(this.ambientLight);
	}

	initShadow(light) {
		light.castShadow = true;
		light.shadow.camera.near = 0.1;
		light.shadow.camera.far = 20;
		light.shadow.camera.left = -2;
		light.shadow.camera.right = 2;
		light.shadow.camera.top = 2;
		light.shadow.camera.bottom = -2;
		light.shadow.mapSize.width = 1024 * 1.5;
		light.shadow.mapSize.height = 1024 * 1.5;
	}

	doLeft() {
		this.avatar.doLeft();
	}

	doRight() {
		this.avatar.doRight();

	}

	tick(delta) {
		this.avatar.update(delta);
	}

	resetAvatar(config) {
		config = config ? config : {};
		let avParent = this.avatar.group.parent;
		if (this.avatar) {
			this.avatar.group.removeFromParent();
			delete this.avatar;
		}
		this.avatar = new Avatar(config);
		avParent.add(this.avatar.group);

		// make sure the avatar isn't left default if the server doesn't return an avatar config
		if (Object.keys(config).length === 0) {
			const simulatedSteps = 4;
			for (let index = 0; index < simulatedSteps; index++) {
				setTimeout(() => { this.avatar.doStepChange({ step: index, steps: simulatedSteps }); }, (index + 1) * 150);
			}
		}
	}

	doStepChange(stepDetails) {
		this.avatar.doStepChange(stepDetails);
	}
}

export default Environment;