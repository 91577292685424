import * as THREE from 'three';
import config from '../config';
import darkModeManager from '../darkModeManager';

const snoise = `// 3D Simplex Noise
vec3 mod289(vec3 x) {
	return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 mod289(vec4 x) {
	return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 permute(vec4 x) {
	return mod289(((x * 34.0) + 10.0) * x);
}
vec4 taylorInvSqrt(vec4 r) {
	return 1.79284291400159 - 0.85373472095314 * r;
}
float snoise(vec3 v) {
	const vec2 C = vec2(1.0 / 6.0, 1.0 / 3.0);
	const vec4 D = vec4(0.0, 0.5, 1.0, 2.0);

	vec3 i = floor(v + dot(v, C.yyy));
	vec3 x0 = v - i + dot(i, C.xxx);

	vec3 g = step(x0.yzx, x0.xyz);
	vec3 l = 1.0 - g;
	vec3 i1 = min(g.xyz, l.zxy);
	vec3 i2 = max(g.xyz, l.zxy);

	vec3 x1 = x0 - i1 + C.xxx;
	vec3 x2 = x0 - i2 + C.yyy;
	vec3 x3 = x0 - D.yyy;

	i = mod289(i);
	vec4 p = permute(permute(permute(i.z + vec4(0.0, i1.z, i2.z, 1.0)) + i.y + vec4(0.0, i1.y, i2.y, 1.0)) + i.x + vec4(0.0, i1.x, i2.x, 1.0));

	float n_ = 0.142857142857;
	vec3 ns = n_ * D.wyz - D.xzx;
	vec4 j = p - 49.0 * floor(p * ns.z * ns.z);
	vec4 x_ = floor(j * ns.z);
	vec4 y_ = floor(j - 7.0 * x_);
	vec4 x = x_ * ns.x + ns.yyyy;
	vec4 y = y_ * ns.x + ns.yyyy;
	vec4 h = 1.0 - abs(x) - abs(y);
	vec4 b0 = vec4(x.xy, y.xy);
	vec4 b1 = vec4(x.zw, y.zw);

	vec4 s0 = floor(b0) * 2.0 + 1.0;
	vec4 s1 = floor(b1) * 2.0 + 1.0;
	vec4 sh = -step(h, vec4(0.0));
	vec4 a0 = b0.xzyw + s0.xzyw * sh.xxyy;
	vec4 a1 = b1.xzyw + s1.xzyw * sh.zzww;
	vec3 p0 = vec3(a0.xy, h.x);
	vec3 p1 = vec3(a0.zw, h.y);
	vec3 p2 = vec3(a1.xy, h.z);
	vec3 p3 = vec3(a1.zw, h.w);

	vec4 norm = taylorInvSqrt(vec4(dot(p0, p0), dot(p1, p1), dot(p2, p2), dot(p3, p3)));
	p0 *= norm.x;
	p1 *= norm.y;
	p2 *= norm.z;
	p3 *= norm.w;

	vec4 m = max(0.5 - vec4(dot(x0, x0), dot(x1, x1), dot(x2, x2), dot(x3, x3)), 0.0);
	m = m * m;
	return 105.0 * dot(m * m, vec4(dot(p0, x0), dot(p1, x1), dot(p2, x2), dot(p3, x3)));
}
`;

const spaceGeometry = new THREE.CylinderBufferGeometry(10, 300, 50 * 20, 32, 1);
spaceGeometry.translate(0, -spaceGeometry.parameters.height / 2 - 0.01, 0);
const spaceMaterial = new THREE.MeshBasicMaterial({
	side: THREE.BackSide,
	transparent: true,
});


let lastFrame = Date.now();
const tick = () => {
	const delta = (Date.now() - lastFrame) / 1000;
	lastFrame = Date.now();
	if (uniforms) {
		uniforms.u_time.value += delta;
	}
	window.requestAnimationFrame(tick);
}
let uniforms = null;

let spaceColor = new THREE.Color(config.colors.light.space);
let spaceColorAlt = new THREE.Color(config.colors.light.spaceAlt);

function setSpaceColors() {
	if (uniforms) {
		uniforms.spaceR = { value: spaceColor.r };
		uniforms.spaceG = { value: spaceColor.g };
		uniforms.spaceB = { value: spaceColor.b };
		uniforms.spaceAltR = { value: spaceColorAlt.r };
		uniforms.spaceAltG = { value: spaceColorAlt.g };
		uniforms.spaceAltB = { value: spaceColorAlt.b };
	}
}

spaceMaterial.needsUpdate = true;
spaceMaterial.onBeforeCompile = function (shader) {
	shader.uniforms.u_time = {
		value: Math.random() * 1000,
	};
	uniforms = shader.uniforms;
	setSpaceColors();
	tick();
	spaceMaterial.userData.shader = shader;
	shader.vertexShader = shader.vertexShader.replace(
		'void main()',
		`
			varying vec4 vWorldPosition;
			void main()
		`);
	shader.vertexShader = shader.vertexShader.replace(
		'#include <begin_vertex>',
		`
		#include <begin_vertex>
		vWorldPosition = modelMatrix * vec4(transformed.xyz, 1.0);
	`);
	shader.fragmentShader = `
	varying vec4 vWorldPosition;
	uniform float u_time;
	uniform float spaceR;
	uniform float spaceAltR;
	uniform float spaceG;
	uniform float spaceAltG;
	uniform float spaceB;
	uniform float spaceAltB;

	${snoise}
	${shader.fragmentShader.replace('#include <color_fragment>', `
		#include <color_fragment>
		
		vec3 pos = vec3(vWorldPosition.xyz) * 0.1;
		float noise = snoise(vec3(pos.x, pos.y, pos.z - u_time * 0.1)) * 0.5 + 0.5;
		if (noise > 0.5 && noise < 0.6) {
			noise = 1.0;
		} else {
			noise = 0.0;
		}

		diffuseColor.x = mix(spaceR, spaceAltR, noise);
		diffuseColor.y = mix(spaceG, spaceAltG, noise);
		diffuseColor.z = mix(spaceB, spaceAltB, noise);
		if (vWorldPosition.y >= -0.1) {
			diffuseColor.a = 0.25;
		}
	`)}`;
};

window.shaderPID = window.shaderPID || 10000;
// Make sure WebGLRenderer doesn't reuse a single program
spaceMaterial.customProgramCacheKey = function () {
	return parseInt(window.shaderPID++); // some random ish number
};

function goDark() {
	spaceMaterial.color.set(config.colors.dark.space);
	spaceColor = new THREE.Color(config.colors.dark.space);
	spaceColorAlt = new THREE.Color(config.colors.dark.spaceAlt);
	spaceMaterial.needsUpdate = true;
	setSpaceColors();
}
function goLight() {
	spaceMaterial.color.set(config.colors.light.space);
	spaceColor = new THREE.Color(config.colors.light.space);
	spaceColorAlt = new THREE.Color(config.colors.light.spaceAlt);
	spaceMaterial.needsUpdate = true;
	setSpaceColors();
}
darkModeManager(goDark, goLight);

class Space {
	constructor(parent) {
		this.mesh = new THREE.Mesh(
			spaceGeometry,
			spaceMaterial
		);
		parent.add(this.mesh);
	}
}

export default Space;