

/*
the structure of the map png is:

_________________________
|      |       |        |
| hair | pants | unused |
|______|_______|________|
|      |       |        |
| skin | shirt | unused |
|______|_______|________|


which makes the array for it:
[hair, pants, unused, skin, shirt, unused]

Unused pixels are held for possible future use.
You may notice it's flipped upside-down from the default one—this is because THREE flips the texture image before applying it.
Attempting to un-flip it via settings (material.map.flipY = false) causes the UV map not to line up properly, so it's easier
to just flip the canvas we're working with
*/

class MatMaker {
	// class that creates and maintains a tiny canvas to make png maps for the character model
	constructor(width, height) {
		this.width = width;
		this.height = height;
		this.canvas = document.createElement('canvas');
		this.canvas.width = width;
		this.canvas.height = height;
		this.ctx = this.canvas.getContext('2d');
		this.imgData = this.ctx.createImageData(width, height);
	}

	//helper functions
	getPixelStart(x, y) {
		//returns the imageData index of the given pixel's red value
		//recall that imageData stores pixels as sets of four Uint8 values in rgba order
		return y * (this.width * 4) + x * 4;
	}

	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	setPixel(hex, x, y) {
		let i = this.getPixelStart(x, y);
		let rgb = this.hexToRgb(hex);

		this.imgData.data[i] = rgb.r;
		this.imgData.data[i + 1] = rgb.g;
		this.imgData.data[i + 2] = rgb.b;
		this.imgData.data[i + 3] = 255;
	}

	setSkin(hex) {
		this.setPixel(hex, 0, 1);
	}

	setHair(hex) {
		this.setPixel(hex, 0, 0);
	}

	setTop(hex) {
		this.setPixel(hex, 1, 1);
	}

	setPants(hex) {
		this.setPixel(hex, 1, 0);
	}

	getCanvas() {
		//applies any changes and returns this object's updated canvas.
		this.ctx.putImageData(this.imgData, 0, 0);
		return this.canvas;
	}

}

export default MatMaker;