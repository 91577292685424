import React, { useRef, useState } from "react";
import Button from "../components/button";
import Container from "../components/container";
import WordCloud from "../components/word-cloud";

function YourDislikes({ nextStep, data }) {
	const [highlight, setHighlight] = useState(false);
	const wordRef = useRef(null);

	return (
		<Container className="text-center">
			<p>
				a little less standard, what are a couple things you dislike the most? get creative!
			</p>
			<WordCloud onChange={(array) => {
				setHighlight(array.length > 0);
			}} limit={4} ref={wordRef} value={data.dislikes} words={[
				//List of commonly disliked things
				"Politics",
				"Religion",
				"Rude people",
				"Cilantro",
				"Sports",
				"Fashion",
				"Politicians",
				"Cults",
				"Spam callers",
				"Memory leaks",
				"Staying inside",
				"Rainy days",
				"Drowning",
				"Classical music",
				"KPop",
				"Social media",
				"Internet bullies",
				"Dogs",
				"Cats",
				"Sweet relish",
				"Pickles",
				"Nickleback",
				"Fast food chains",
				"Junk food",
				"Asking for directions",
				"Admitting I'm wrong",
				"Nicolas Cage merchandise",
				"Crying in public",
				"Crying in my own car",
				"dirty laundry",
				"closet skeletons",
				"Marvel movies",
				"DC comics",
			]} />
			<div className="flex justify-center items-center">
				<Button className={highlight ? 'bg-secondary text-black' : ''} onClick={() => {
					const words = wordRef.current.getWords();
					if (words.length > 0) nextStep('dislikes', words);
				}}>finish</Button>
			</div>
		</Container>

	);
}

export default YourDislikes;
