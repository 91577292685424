import React, { useState } from "react";


function ReportButton(props) {
	const [reported, setReported] = useState(false);
	return (
		<span {...props} onClick={e => {
			if (reported) return;
			fetch('https://api.youaresometa.com/report/' + props.uid + '?t=' + Date.now());
			setReported(true);
		}}>
			{!reported ? props.children : 'Thanks for flagging this!'}
		</span>
	)
}

export default ReportButton;