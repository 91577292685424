import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const hairPath = '/assets/3d/';

class Hair {
	constructor(config, texture) {
		this.group = new THREE.Group();
		this.config = config;
		this.model = null;
		this.mat = null;

		if (this.config.hairStyle === "none") {
			this.group.visible = false;
			return;
		}

		//load in the requested hair model here, 
		let loadedModel;
		let boundInit = this.initialize.bind(this);

		const loader = new GLTFLoader();
		console.log("loading hairstyle " + this.config.hairStyle);
		loader.load(hairPath + hairStyleMap[this.config.hairStyle],
			(gltf) => { //callback on success
				loadedModel = gltf.scene;
				boundInit(loadedModel, texture);
				console.log("hairstyle loaded");
			},
			undefined, //callback on wait
			(error) => { //callback on fail
				console.error(error);
			}
		);

	}

	initialize(loadedModel, texture) {
		if (this.model) {
			this.model.removeFromParent();
			delete this.model;
		}
		this.model = loadedModel;
		let mesh = this.model.children[0];
		mesh.position.x = 0;
		mesh.position.y = 0;
		mesh.position.z = 0;

		this.mat = mesh.material;
		this.mat.roughness = 0.95;
		this.applyColor(texture);

		this.group.add(this.model);
	}

	applyColor(textureMap) {
		//console.log(this.mat);
		this.mat.map = textureMap;
	}
}

export default Hair;

//match the nice hairstyle names with corresponding model files
const hairStyleMap = {
	"none": "DS_person_hair_close.glb", // even bald should load something, just in case.
	"close": "DS_person_hair_close.glb",
	"high pony": "DS_person_hair_highpony.glb",
	"long": "DS_person_hair_long.glb",
	"lumpy": "DS_person_hair_lumpy.glb",
	"wedge": "DS_person_hair_wedge.glb",
}