import * as THREE from 'three';

class Poof {
	constructor(particleCount, color) {
		this.particleCount = particleCount ? particleCount : 255;
		this.color = color ? color : "#DDDDDD";

		this.particles = new THREE.BufferGeometry();
		this.material = new THREE.PointsMaterial({
			color: this.color,
			size: 2,
		});

		let verts = [];
		for (let i = 0; i < this.particleCount; i++) {
			//create each "particle." Note that in THREE, particle systems are just meshes, with the particles at the vertices
			//since the goal of this particle effect is to explode outward from a central point, we'll start with all vertices at its origin
			//                                                 \/ this value is the max radius of the particle cloud
			let p = new THREE.Vector3().setFromSphericalCoords(1.5, Math.random() * 2 * Math.PI, Math.random() * 2 * Math.PI);
			verts.push(p);
		}

		this.particles.setFromPoints(verts);

		this.particleSystem = new THREE.Points(
			this.particles,
			this.material,
		);

		this.particleSystem.visible = false;
		this.particleSystem.scale.setScalar(0.0);

		//animation duration in seconds
		this.animLength = 1;
		// we want a bursty animation curve, so here's an exponential ease-out as a cubic bezier
		// we can get any magnitude along it by grabbing the y value of the coordinate returned by .getPoint(t)
		this.animCurve = new THREE.CubicBezierCurve(
			new THREE.Vector2(0, 0),
			new THREE.Vector2(0, 1.3),
			new THREE.Vector2(0, 0.91),
			new THREE.Vector2(1, 1.1), //normally we'd stop at 1,1, but we want to overshoot just a tiny bit so our particles fade out all the way
		);
		// value between 0 and animLength representing how long the animation has been played.
		this.animTime = 0;
		this.playing = false;

	}

	start() {
		this.playing = true;
		this.particleSystem.visible = true;
	}

	stop() {
		this.particleSystem.visible = false;
		this.playing = false;
	}

	reset() {
		this.particleSystem.scale.setScalar(0);
		this.material.size = 2;
		this.animTime = 0;
	}

	update(dt) {
		this.animTime = (this.animTime + dt);
		if (this.playing) {
			if (this.animTime <= this.animLength) {
				let t = this.animTime / this.animLength;
				let p = this.animCurve.getPoint(t).y;

				this.particleSystem.scale.setScalar(p);
				this.material.size = Math.max(2 - (p * 2), 0);
			}
			else {
				this.stop();
			}
		}
		else {
			this.stop();
			this.reset();
		}
	}

}

export default Poof;