//fetch('/.netlify/functions/prompt', {method: 'POST', body: JSON.stringify({name: "caleb", likes: "turtles, penguins, zoos", dislikes: "politics, anger, bad stuff"})});
import React, { useState } from "react";
import Button from "../components/button";
import Container from "../components/container";
import DocumentMeta from 'react-document-meta';
import ReportButton from "../components/reportButton";

function Spoiler({ children, warning }) {
	const [blurMe, setBlurMe] = useState(true);
	return (<div className="relative cursor-pointer" onClick={() => { setBlurMe(!blurMe) }}>
		<p className={(blurMe ? "filter blur" : "")}>{children}</p>
		{blurMe ? <div className="absolute inset-0 w-full h-full flex justify-center align-center items-center">
			{warning}
		</div> : <></>}
	</div>)
}

class Result extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: false,
			resultOffset: 0,
		}
		if (props.data.offset === 1) {
			this.state.resultOffset = 1;
		}
		this.inputs = {
			...this.props.data,
			...this.props.data.inputs,
		}
		if (this.props.data.openai) {
			this.state.data = {
				...this.props.data,
				...this.props.data.inputs,
			};
		}
		if (!this.state.data && props.data && props.data.name && props.data.likes && props.data.dislikes) {
			this.state.data = {
				...props.data,
			};
			this.displayResults();
		}
	}

	displayResults() {
		if (this.fetching) return;
		if (
			this.state.data &&
			this.state.data.openai &&
			this.state.data.openai.choices &&
			this.state.resultOffset < this.state.data.openai.choices.length - 1
		) {
			this.setState({ resultOffset: this.state.resultOffset + 1 });
			window.history.pushState(null, null, '/futures/' + String(this.state.data.uid) + '-' + (this.state.resultOffset + 1));
			return
		}
		this.fetching = true;

		this.setState({
			data: false,
			resultOffset: 0,
			error: false,
		});
		fetch('https://api.youaresometa.com/prompt/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: this.inputs.name,
				likes: this.inputs.likes,
				dislikes: this.inputs.dislikes,
				avatar: this.props.avatar,
			})
		}).then(result => result.json()).then(data => {
			this.fetching = false;
			if (data.error) {
				this.setState({ error: true });
			} else {
				this.setState({ data: data, error: false })
				window.history.pushState(null, null, '/futures/' + data.uid);
			}
		}).catch(err => {
			console.error(err);
			this.fetching = false;

			this.setState({
				error: true,
				data: false,
			});
		});
	}

	twitterTruncate(string) {
		if (string.length > 150) {
			return string.substring(0, 150).trim() + "...";
		}
		else {
			return string;
		}
	}

	render() {
		let canonical = 'https://youaresometa.com/';
		try {
			canonical = 'https://youaresometa.com/futures/' + this.state.data.uid + (this.state.resultOffset > 0 ? '-' + this.state.resultOffset : '');
		} catch (e) { }
		const finished = this.state.data && this.state.data.openai;
		let output = '';
		let title = '';
		let safety = 1;
		if (finished) {
			output = this.state.data.start + this.state.data.openai.choices[this.state.resultOffset].text;
			title = "Metaverse: " + this.inputs.name + " is soooo meta!";

			safety = Number(this.state.data.openai.choices[this.state.resultOffset].filterRating) || 0;
		}

		// safe result
		let display = <p>
			{output}
		</p>
		console.log('safety', safety);

		// potentially harmful result
		try {
			if (safety >= 1) {
				display = <div>
					<Spoiler warning={<p className='text-xs'>It looks like the AI may have output something offensive based on user inputs. Click to reveal.</p>}>
						{output}
					</Spoiler>
				</div>
			}
		} catch (e) { }

		// potentially VERY harmful result
		try {
			if (safety >= 2) {
				display = <div>
					<Spoiler warning={<p className='text-xs'>It looks like the AI probably got offensive because of user-inputs. Click to reveal.</p>}>
						{output}
					</Spoiler>
				</div>
			}
		} catch (e) { }

		return (
			<Container className="text-center">
				<DocumentMeta meta={{
					name: {
						'twitter:site': '@6connect',
						'twitter:title': safety <= 1 ? title : 'Metaverse Generator',
						'twitter:card': 'summary_large_image',
						'twitter:image': 'https://api.youaresometa.com/preview/' + this.state.data.uid,
						'twitter:description': safety <= 1 ? output : 'Metafy your future in the Metaverse!',
					},
					property: {
						'og:image': 'https://api.youaresometa.com/preview/' + this.state.data.uid,
						'og:description': safety <= 1 ? output : 'Metafy your future in the Metaverse!',
					}
				}}>
					{
						this.state.error ?
							<div>Oops, there was an error!</div> :
							finished ? <>
								{this.state.data.newUser ? <small title="Not an actual fortune telling">
									An AI generated future for {this.state.data.inputs.name}
								</small> : <></>}
								{display}
							</> : <p>
								Using AI to predict the future...
							</p>
					}
					{this.state.data.newUser ? <Button onClick={this.props.nextStep}>
						Predict your future!
					</Button> : <>
						{finished || this.state.error ?
							<Button className='bg-secondary text-black' onClick={this.displayResults.bind(this)}>
								Next Result
							</Button> : <></>}
						{finished || this.state.error ?
							<Button onClick={this.props.nextStep}>
								Start Over
							</Button> : <></>}
					</>}

					{finished && !this.state.error ?
						<div className="w-full flex text-center justify-center align-middle">
							<span onClick={e => {
								//copy url to clipboard
								navigator.clipboard.writeText(canonical)
							}} className="px-2 my-1 block text-sm cursor-pointer">
								🔗 <u>Copy Link</u>
							</span>
							<a data-size="large" target="_blank" rel="noreferrer noopener" href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(`${this.twitterTruncate(output)} #SoMeta`)}&url=${encodeURIComponent(canonical)}`}>
								<span className="px-2 my-1 block text-sm underline">
									<svg className="inline mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
									Tweet
								</span>
							</a>
						</div> : <></>}

					{(this.state.data.newUser || safety > 0) ? <p>
						<ReportButton className="text-xs" uid={this.state.data ? this.state.data.uid : 0}>
							<span className="cursor-pointer underline">
								Flag offensive content.
							</span>
						</ReportButton>
					</p> : <></>}
				</DocumentMeta>
			</Container>
		);
	}
}

export default Result;
